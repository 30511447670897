<template>
  <div
    style="width: 100%; height: 100%;"
  >
    <!-- MOBILE -->
    <v-overlay
      v-if="$vuetify.breakpoint.mobile"
      absolute
      :value="loading"
    >
      <v-progress-circular
        :size="50"
        color="white"
        indeterminate
      />
    </v-overlay>

    <v-container
      v-if="$vuetify.breakpoint.mobile"
      fluid
      fill-height
      class="pa-0 align-center justify-center"
      :style="`background-color: white; height: ${$vuetify.breakpoint.height}px; width: ${$vuetify.breakpoint.width}px; overflow-y: auto;`"
    >
      <form
        class="px-5"
        @enter="submit"
      >
        <div class="d-flex align-center justify-center pt-4 pb-4">
          <img
            src="@/assets/Lavirra_logo_SVG.svg"
            height="60px"
            width="auto"
          >
        </div>
        <v-row>
          <h3 class="mx-auto">
            {{ $t('profile|log_in') }}
          </h3>
        </v-row>
        <v-text-field
          ref="Mail"
          v-model="email"
          :error-messages="emailErrors"
          :label="$t('common|email')"
          required
          outlined
          height="48"
          class="mt-2"
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
          @keyup.enter="submit"
        />

        <!-- to activate eye to show pass
      :type="showPass ? 'text' : 'password'"
      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" -->
        <v-text-field
          ref="Pass"
          v-model="password"
          :error-messages="passwordErrors"
          :label="$t('common|password')"
          type="password"
          required
          outlined
          height="48"
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
          @keyup.enter="submit"
          @click:append="showPass = !showPass"
        />
        <v-alert
          class="mt-2 mb-4"
          :value="!!error"
          type="error"
          transition="scale-transition"
        >
          {{ error }}
        </v-alert>
        <div class="d-flex justify-center align-center">
          <v-btn
            color="primary"
            class="mt-4"
            large
            min-width="300"
            @click="submit"
          >
            {{ $t('profile|log_in') }}
          </v-btn>
        </div>
        <div class="d-flex justify-center align-center mt-5">
          <router-link to="/register">
            {{ $t('profile|register') }}
          </router-link>
        </div>
        <div class="d-flex justify-center align-center mt-5">
          <router-link to="/forgotpassword">
            {{ $t('warning|forgot_password') }}?
          </router-link>
        </div>
      </form>
    </v-container>

    <!-- DESKTOP -->
    <v-container
      v-else
      fluid
      fill-height
      class="pa-0"
    >
      <div class="center-item">
        <form
          class="elevation-3 pa-10 rounded-lg"
          @enter="submit"
        >
          <div class="d-flex align-center justify-center pt-4 pb-4">
            <img
              src="@/assets/Lavirra_logo_SVG.svg"
              height="60px"
              width="auto"
            >
          </div>
          <v-row>
            <h3 class="mx-auto">
              {{ $t('profile|log_in') }}
            </h3>
          </v-row>
          <v-text-field
            ref="Mail"
            v-model="email"
            :error-messages="emailErrors"
            :label="$t('common|email')"
            required
            outlined
            height="48"
            class="mt-2"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
            @keyup.enter="submit"
          />

          <!-- to activate eye to show pass
          :type="showPass ? 'text' : 'password'"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" -->
          <v-text-field
            ref="Pass"
            v-model="password"
            :error-messages="passwordErrors"
            :label="$t('common|password')"
            type="password"
            required
            outlined
            height="48"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
            @keyup.enter="submit"
            @click:append="showPass = !showPass"
          />
          <v-alert
            class="mt-2 mb-4"
            :value="!!error"
            type="error"
            transition="scale-transition"
          >
            {{ error }}
          </v-alert>
          <div class="d-flex justify-center align-center">
            <v-btn
              color="primary"
              class="mt-4"
              large
              min-width="300"
              @click="submit"
            >
              {{ $t('profile|log_in') }}
            </v-btn>
          </div>
          <div class="d-flex justify-center align-center mt-5">
            <router-link to="/register">
              {{ $t('profile|register') }}
            </router-link>
          </div>
          <div class="d-flex justify-center align-center mt-5">
            <router-link to="/forgotpassword">
              {{ $t('warning|forgot_password') }}?
            </router-link>
          </div>
          <v-overlay
            absolute
            :value="loading"
          >
            <v-progress-circular
              :size="50"
              color="white"
              indeterminate
            />
          </v-overlay>
        </form>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minLength, email, helpers } from 'vuelidate/lib/validators'
import store from '@/store/index'
const alphaNum = helpers.regex('alphaNumAndDot', /^[a-z\d.!#$%&()*+,./=@[\]^_|~]*$/i)

export default {
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(7), alphaNum }
  },
  data () {
    return {
      email: null,
      password: null,
      loading: false,
      error: null,
      showPass: false,
      ele: null,
      evt: null,
      token: null
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    }),
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors

      !this.$v.email.email && errors.push(this.$t('warning|valid_email'))
      !this.$v.email.required && errors.push(this.$t('warning|email_required'))

      return errors
    },
    passwordErrors () {
      const errors = []

      if (!this.$v.password.$dirty) return errors

      !this.$v.password.required && errors.push(this.$t('warning|password_required'))
      !this.$v.password.minLength && errors.push(this.$t('warning|password_type'))
      !this.$v.password.alphaNum && errors.push(`${this.$t('warning|forbidden_special_characters')}: { } " - ? : ' < > ${'`'} `)

      return errors
    }
  },
  mounted () {
    if (this.$vuetify.theme.themes.light.primary === '#546E7A') {
      this.$vuetify.theme.themes.light.primary = '#234e79'
    }
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    if (isChrome) {
      // if (this.$refs.Pass.valid) {
      //   this.$refs.Pass.label = ''
      //   // this.$refs.Pass.focus()
      // }
      // if (this.$refs.Mail.valid) {
      //   this.$refs.Mail.label = ''
      //   // this.$refs.Mail.focus()
      // }
    }
  },
  created () {
    localStorage.clear()
  },
  methods: {
    ...mapActions({
      setToken: 'auth/setToken',
      setAccount: 'account/setAccount'
    }),
    async submit () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.loading = true
        try {
          const { data } = await this.$axios.post('/accountLogin', {
            email: this.email,
            password: this.password
          })
          if (data && !data.success && data.msg === 'Account is not verified.') this.error = this.$t('warning|account_not_verified')
          else this.error = null
          if (data && data.success) {
            await this.setAccount(data.data)
            await this.setToken(data)
            await this.$axios.get('/getRefreshCookie', { withCredentials: true })
            const page = this.account.accountType === 'god' ? 'templates' : 'dashboard'
            this.$router.push({ path: `/${page}` })
          }
        } catch (e) {
          this.loading = false
          this.error = this.$t('error|authentification_error')
        } finally {
          this.loading = false
        }
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    const accType = localStorage.getItem('type')
    const page = accType && accType === 'god' ? 'templates' : 'dashboard'
    const isLoggedIn = localStorage.getItem('access')
    if (isLoggedIn === 'true') {
      next(`/${page}`)
    } else {
      store.dispatch('auth/removeToken')
      store.dispatch('account/resetAccount')
      next()
    }
  }
}
</script>

<style lang="scss" scoped>
  form {
    position: relative;
    width: 500px;
    background-color: rgb(255, 255, 255);
  }
  .center-item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
